<!--
 * @Author: ChenYaJin
 * @Date: 2023-07-04 16:56:56
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-09-03 09:35:29
 * @Description: 可配置表单组件
-->
<template>
  <el-form
    ref="customFormRef"
    :model="tableData"
    :rules="rules"
    :validate-on-rule-change="false"
    :disabled="!isView"
  >
    <el-row
      v-for="(row, index) in tableSetting"
      :key="'row' + index"
      :class="['row', showTableLine ? 'row-border' : '']"
    >
      <el-col
        v-for="(item, itemIndex) in row"
        :key="index + 'item' + itemIndex"
        :span="24 / row.length"
        class="col-item"
        @click="setCurrentCol(item)"
      >
        <span
          :class="[
            item.required ? 'required-before' : '',
            showTableLine ? 'border-right background-color' : '!justify-end',
          ]"
          class="item-label"
        >
          {{ item.label }}
        </span>
        <div :class="['item-content', showTableLine ? 'border-right' : '']">
          <el-form-item class="!m-0 !w-full" :prop="item.fieldName">
            <!-- 输入框 -->
            <template v-if="item.type === ETableItemType.INPUT">
              <template v-if="isView && !isForm">
                {{ tableData[item.fieldName] || '-' }}
              </template>
              <template v-else>
                <el-input
                  v-model.trim="tableData[item.fieldName]"
                  :placeholder="item.placeholder"
                  :type="item.meta?.inputType || 'text'"
                  size="large"
                  class="!w-full"
                ></el-input>
              </template>
            </template>
            <!-- 单选radio -->
            <template v-if="item.type === ETableItemType.RADIO">
              <template v-if="isView && !isForm">
                {{ tableData[item.fieldName] ? '是' : '否' }}
              </template>
              <template v-else>
                <!-- radio 单选 -->
                <el-radio-group
                  v-model="tableData[item.fieldName]"
                  class="!w-ful"
                  @change="updateRadioContent"
                >
                  <el-radio :label="true" size="large">是</el-radio>
                  <el-radio :label="false" size="large">否</el-radio>
                </el-radio-group>
              </template>
            </template>
            <!-- 下拉选 -->
            <template v-if="item.type === ETableItemType.SELECT">
              <template v-if="isView && !isForm">
                {{ getOptionName(formOptions[item.fieldName], tableData[item.fieldName]) }}
                <template v-if="item.condition && item.condition[tableData[item.fieldName]]">
                  <div v-if="item.condition[tableData[item.fieldName]].multiple">
                    <span class="ml-1rem">
                      {{
                        getOptionNames(
                          formOptions[item.condition[tableData[item.fieldName]].optionsName],
                          tableData[item.condition[tableData[item.fieldName]].fieldName]
                        )
                      }}
                    </span>
                  </div>
                  <div v-else>
                    <span class="ml-1rem">
                      {{
                        getOptionName(
                          formOptions[item.condition[tableData[item.fieldName]].optionsName],
                          tableData[item.condition[tableData[item.fieldName]].fieldName]
                        )
                      }}
                    </span>
                  </div>
                </template>
              </template>
              <template v-else>
                <el-select
                  v-model="tableData[item.fieldName]"
                  :placeholder="item.placeholder"
                  class="!w-full flex-1"
                  :multiple="item.meta?.multiple || false"
                >
                  <el-option
                    v-for="option in formOptions[item.fieldName]"
                    :key="option.code"
                    :label="option.name"
                    :value="option.code"
                  ></el-option>
                </el-select>
                <!-- 条件匹配筛选 联动选择 -->
                <template v-if="item.condition && item.condition[tableData[item.fieldName]]">
                  <el-form-item
                    class="!m-0 !w-full flex-1"
                    :prop="item.condition[tableData[item.fieldName]].fieldName"
                    :show-message="false"
                    :required="
                      tableData[item.fieldName] ===
                        item.condition[tableData[item.fieldName]].matchCode &&
                      item.condition[tableData[item.fieldName]].required
                    "
                  >
                    <el-select
                      v-model="tableData[item.condition[tableData[item.fieldName]].fieldName]"
                      :placeholder="
                        item.condition[tableData[item.fieldName]].placeholder || '请选择'
                      "
                      class="!w-full"
                      :multiple="item.condition[tableData[item.fieldName]].multiple || false"
                    >
                      <el-option
                        v-for="option in formOptions[
                          item.condition[tableData[item.fieldName]].optionsName
                        ]"
                        :key="option.code"
                        :label="option.name"
                        :value="option.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </template>
            </template>
            <!-- 日期选择 -->
            <template v-if="item.type === ETableItemType.DATE">
              <template v-if="isView && !isForm">
                {{ tableData[item.fieldName] || '-' }}
              </template>
              <template v-else>
                <el-date-picker
                  v-model="tableData[item.fieldName]"
                  :placeholder="item.placeholder"
                  :value-format="item.meta?.valueFormat ?? 'YYYY-MM-DD'"
                  :type="item.meta?.dateType ?? 'date'"
                  class="!w-full"
                />
              </template>
            </template>
            <!-- 地址填写 -->
            <template v-if="item.type === ETableItemType.ADDRESS">
              <template v-if="isView && !isForm">
                {{ tableData[item.fieldName] || '-' }}
              </template>
              <template v-else>
                <div class="flex-col items-start w-full p-v-0.5rem">
                  <el-cascader
                    ref="regionRef"
                    v-model="region"
                    clearable
                    :props="loadProps"
                    placeholder="请选择省市区"
                    class="address-wrapper w-full"
                    @change="selectArea"
                  />
                  <el-input
                    v-model="tableData[item.fieldName]"
                    placeholder="请填写详细地址"
                    class="w-full"
                  ></el-input>
                </div>
              </template>
            </template>

            <template v-if="item.type === ETableItemType.ADDRESS_AREA">
              <template v-if="isView && !isForm">
                {{ tableData[item.fieldName] || '-' }}
              </template>
              <template v-else>
                <div class="flex-col items-start w-full p-v-0.5rem">
                  <el-cascader
                    ref="regionRef"
                    v-model="region"
                    clearable
                    :props="loadProps"
                    placeholder="请选择省市区"
                    class="address-wrapper w-full"
                    @change="selectArea"
                  />
                </div>
              </template>
            </template>
            <template v-if="item.type === ETableItemType.ADDRESS_DETAIL">
              <template v-if="isView && !isForm">
                {{ tableData[item.fieldName] || '-' }}
              </template>
              <template v-else>
                <div class="flex-col items-start w-full p-v-0.5rem">
                  <el-input
                    v-model="tableData[item.fieldName]"
                    placeholder="请填写详细地址"
                    class="w-full"
                  ></el-input>
                </div>
              </template>
            </template>

            <!-- 图片上传 -->
            <template v-if="item.type === ETableItemType.UPLOAD_IMAGE">
              <template v-if="isView && !isForm">
                <div v-if="isHasImage(item.fieldName)">
                  <span v-for="(img, imgIndex) in getImageUrl(item.fieldName)" :key="img.name">
                    <el-image
                      class="w-80px mr-0.5rem"
                      :src="img.url"
                      :zoom-rate="1.2"
                      :preview-src-list="getImageUrlString(item.fieldName)"
                      :initial-index="initialIndex"
                      fit="cover"
                      @click="setInitialIndex(imgIndex)"
                    />
                  </span>
                </div>
                <span v-else>-</span>
              </template>
              <template v-else>
                <div class="h-full p-v-0.5rem" @click="setCurrentCol(item)">
                  <el-upload
                    :file-list="getImageUrl(item.fieldName)"
                    class="avatar-uploader"
                    :action="item?.meta?.actionUrl || fileActionUrl"
                    list-type="picture-card"
                    :headers="getHeaders"
                    :limit="item?.meta?.maxLimit || 1"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                  >
                    <div class="avatar-uploader-icon flex items-center justify-center">
                      <i class="i-ep-plus"></i>
                    </div>
                  </el-upload>
                  <span>{{ item.placeholder }}</span>
                </div>
                <el-dialog v-model="dialogVisible">
                  <img w-full :src="dialogImageUrl" alt="Preview Image" />
                </el-dialog>
              </template>
            </template>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script setup lang="ts">
  import type { UploadProps, FormInstance, FormRules, CascaderProps } from 'element-plus'
  import { ElMessage } from 'element-plus'

  import { apiBase } from '~/constants'
  import type { ITableItemSetting } from '~/models/table-setting'
  import { ETableItemType } from '~/models/enums'
  import type { IOption, BasicResponse } from '~/models/common'
  import { getCityList, getDistrictList } from '~/api/options'
  import { useOptionStore } from '~/stores/modules/option'
  import { getToken } from '~/utils/auth'
  import { CompressImage } from '~/utils/image-compress'
  import { getImagesBase64, type ImagesList } from '~/api/options'

  defineOptions({
    name: 'TableSettingComponent',
  })
  const customFormRef = ref<FormInstance>()
  const props = defineProps({
    // 是否表单状态
    isForm: {
      type: Boolean,
      default: false,
    },
    // 是否是编辑状态
    isView: {
      type: Boolean,
      default: false,
    },
    // 是否显示表格边框背景色
    showTableLine: {
      type: Boolean,
      default: true,
    },
    // 表单配置数据
    tableSetting: {
      type: Array<ITableItemSetting[]>,
      default: () => {
        return []
      },
    },
    // 表单选项
    formOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 表单数据
    formData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    fileSize: {
      type: Number,
      default: 10, //单位: M
    },
  })
  const regionRef = ref()
  const tableData = ref(props.formData)
  defineExpose({ customFormRef, tableData })
  const currentCol = ref<ITableItemSetting>()
  const imagesUrl = ref<string[]>([])
  // const imageActionUrl = computed(() => {
  //   return apiBase + '/file/images/upload'
  // })
  const fileActionUrl = computed(() => {
    return apiBase + '/file/upload'
  })
  const region = ref<string[]>([])
  const rules = reactive<FormRules<typeof tableData>>({})
  const setRules = () => {
    props.tableSetting.forEach((row) => {
      ;(row || []).forEach((col) => {
        rules[col.fieldName] = [
          {
            required: col.required,
            message: col.requiredMessage || col.placeholder,
            trigger: 'blur',
          },
        ]
        if (col.validator) {
          rules[col.fieldName] = [
            {
              required: col.required,
              validator: col.validator,
              trigger: 'blur',
            },
          ]
        }
        // if (col.condition) {
        //   const conditions = Object.values(col.condition) || []
        //   conditions.forEach((con) => {
        //     rules[con.fieldName] = [{ required: con.required, message: '' }]
        //   })
        // }
      })
    })
  }
  // 预览模式下，图片字段对应的base64资源(废弃)
  const imageList = ref({})
  // 并发请求图片base64(废弃)
  //修改为文件路径
  const getImageList = () => {
    const idContext: Record<string, string[]> = {}
    props.tableSetting.forEach((row) => {
      ;(row || []).forEach((col) => {
        if (col.type === ETableItemType.UPLOAD_IMAGE) {
          const ids = Reflect.get(tableData.value, col.fieldName)
          if (col.fieldName && ids) {
            const arrIds = typeof ids === 'object' ? ids : [ids]
            Reflect.set(idContext, col.fieldName, arrIds)
          }
        }
      })
    })
    const keys: string[] = Object.keys(idContext)
    // const promises: any[] = []
    Object.values(idContext).forEach((item: string[], index) => {
      if (item && item.length) {
        // const ids = item.join(',')
        // promises.push(getImagesBase64(ids))
        const content = (item || []).map((id) => {
          return {
            name: id,
            url: `${apiBase}/file/download/${id}`,
          }
        })
        Reflect.set(imageList.value, keys[index], content)
      }
    })
    // Promise.all(promises).then((res) => {
    //   if (res) {
    //     ;(res || []).forEach((data, index) => {
    //       const content = (data.data || []).map((item: ImagesList) => {
    //         return {
    //           name: item.fileId,
    //           url: 'data:image/png;base64,' + item.base64,
    //         }
    //       })
    //       Reflect.set(imageList.value, keys[index], content)
    //     })
    //   }
    // })
  }
  const isHasImage = (fieldName: string) => {
    const content = Reflect.get(imageList.value, fieldName)
    return content && content.length
  }
  const getImageUrl = (fieldName: string) => {
    const content = Reflect.get(imageList.value, fieldName)
    return content || []
  }
  const initialIndex = ref(0)
  const setInitialIndex = (index: number) => {
    initialIndex.value = index
  }
  const getImageUrlString = (fieldName: string) => {
    const content = getImageUrl(fieldName)
    return (content || []).map((item: ImagesList) => item.url)
  }
  const setRegionData = () => {
    const { provinceCode, cityCode, districtCode } = tableData.value
    const hasData = !!provinceCode && !!cityCode && !!districtCode
    region.value = hasData ? [provinceCode, cityCode, districtCode] : []
    return hasData
  }
  watch(
    () => props.formData,
    (newValue) => {
      tableData.value = JSON.parse(JSON.stringify(newValue))
      imageList.value = {}
      getImageList()
      setRegionData()
    },
    {
      immediate: true,
    }
  )
  watch(
    () => props.isView,
    (newValue, oldValue) => {
      if (newValue && !oldValue) {
        tableData.value = JSON.parse(JSON.stringify(props.formData))
        getImageList()
      }
      // 预览状态，移除校验信息
      if (newValue) {
        customFormRef?.value?.clearValidate()
      }
    },
    {
      immediate: true,
    }
  )
  watch(
    () => props.tableSetting,
    (newValue) => {
      if (newValue && newValue.length) {
        setRules()
      }
    },
    {
      immediate: true,
    }
  )
  const getHeaders = computed(() => {
    const token = getToken()
    return {
      Authorization: 'Bearer ' + token,
    }
  })
  const getOptionName = (options: IOption[], code: string) => {
    const target = (options || []).find((item) => item.code === code)
    return target?.name || '-'
  }
  const getOptionNames = (options: IOption[], codes: string[]) => {
    const target = (options || [])
      .filter((item) => codes.includes(item?.code || ''))
      .map((item) => item.name)
    return target.join('/')
  }
  // 记录当前操作的单元格
  const setCurrentCol = (item: ITableItemSetting) => {
    currentCol.value = item
  }
  // 地址选项
  const optionStore = useOptionStore()
  const loadProps: CascaderProps = {
    lazy: true,
    async lazyLoad(node, resolve) {
      const { data = {} } = node
      let nodes: any[] = []
      if (data && data.type === 'province' && data.value) {
        getCityList(data?.value.toString()).then((res) => {
          const data = res.data
          nodes = getOptions(data, 'city', false)
          resolve(nodes)
        })
      } else if (data && data.type === 'city' && data.value) {
        getDistrictList(data?.value?.toString() || '').then((res) => {
          const data = res.data
          nodes = getOptions(data, 'district', true)
          resolve(nodes)
        })
      } else {
        optionStore.getProvinceListInfo().then((res) => {
          nodes = getOptions(res, 'province', false) || []
          if (setRegionData()) {
            const { provinceCode, cityCode } = tableData.value
            const promises = [getCityList(provinceCode), getDistrictList(cityCode)]
            Promise.all(promises).then((res) => {
              const cityListInfo = getOptions(res[0]?.data || [], 'city', false)
              const districtListInfo = getOptions(res[1]?.data || [], 'district', true)
              cityListInfo.forEach((item) => {
                if (item.value === cityCode) {
                  item.children = districtListInfo as IOption[]
                }
              })
              nodes.forEach((item) => {
                if (item.value === provinceCode) {
                  item.children = cityListInfo
                }
              })
              resolve(nodes)
            })
          } else {
            resolve(nodes)
          }
        })
      }
    },
  }
  const getOptions = (nodes: IOption[], type = 'province', leaf = false): IOption[] => {
    return nodes.map((node) => ({
      value: node.id,
      label: node.name,
      type: type,
      leaf: leaf,
      children: [],
    }))
  }
  const selectArea = (ids: string[]) => {
    if (ids && ids.length) {
      tableData.value.provinceCode = ids[0]
      tableData.value.cityCode = ids[1]
      tableData.value.districtCode = ids[2]
      const nodes = regionRef?.value[0].getCheckedNodes()
      const pathLabels = nodes[0].pathLabels
      tableData.value.address = pathLabels.join('')
    } else {
      tableData.value.provinceCode = ''
      tableData.value.cityCode = ''
      tableData.value.districtCode = ''
      tableData.value.address = ''
    }
  }
  // 上传图片选项
  const dialogImageUrl = ref('')
  const dialogVisible = ref(false)
  //修改为文件路径
  const handleAvatarSuccess: UploadProps['onSuccess'] = (res: any, file: any) => {
    // 单文件
    if (res.data) {
      imagesUrl.value[0] = res.data
        ? `${apiBase}/file/download/${res.data}`
        : URL.createObjectURL(file.raw)
      // !!res.data.base64
      //   ? 'data:image/png;base64,' + res.data.base64
      //   : URL.createObjectURL(file.raw)
    }
    const fieldName = currentCol.value?.fieldName
    // 单选
    if (fieldName && !currentCol.value?.meta?.multiple) {
      tableData.value[fieldName] = res.data
      let content = {
        name: res.data,
        url: imagesUrl.value[0],
      }
      Reflect.set(imageList.value, fieldName, [content])
    }
    //多选
    if (fieldName && currentCol.value?.meta?.multiple) {
      tableData.value[fieldName].push(res.data)
      const hasExist = Reflect.get(imageList.value, fieldName) || []
      let content = hasExist.concat([
        {
          name: res.data,
          url: imagesUrl.value[0],
        },
      ])
      Reflect.set(imageList.value, fieldName, content)
    }
  }
  const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
    dialogImageUrl.value = uploadFile.url || ''
    dialogVisible.value = true
  }
  const handleRemove: UploadProps['onRemove'] = (uploadFile) => {
    setTimeout(() => {
      const fieldName = currentCol.value?.fieldName
      if (fieldName && !currentCol.value?.meta?.multiple) {
        tableData.value[fieldName] = ''
      }
      if (fieldName && currentCol.value?.meta?.multiple) {
        const id = uploadFile?.name || (uploadFile?.response as BasicResponse<ImagesList>)?.data?.id
        if (id) {
          tableData.value[fieldName] =
            (tableData.value[fieldName] || []).filter((item: string) => item !== id) || []
        }
      }
    }, 200)
  }
  const handleExceed: UploadProps['onExceed'] = () => {
    let maxLimit = currentCol.value?.meta?.maxLimit ?? 1
    ElMessage.error(`图片不能超过 ${maxLimit} 张!`)
  }
  /**
   * 如果源文件大小 < 300KB, 上传源文件；
   * 如果源文件大小 300KB < size < 10M 内，压缩至300kb；
   * 如果源文件大小超过10M，阻止上传；
   * ~param rawFile 源文件
   * ~returns resultFile 压缩后的文件
   */
  const beforeAvatarUpload: UploadProps['beforeUpload'] = async (rawFile: File) => {
    let resultFile = rawFile
    const isJPG = ['image/jpg', 'image/jpeg', 'image/png'].includes(rawFile.type)
    let isUnValidate = rawFile.size / 1024 / 1024 > props.fileSize
    const fieldName = currentCol.value?.fieldName || ''
    let maxLimit = currentCol.value?.meta?.maxLimit ?? 1
    let isMax = getImageUrl(fieldName).length >= maxLimit
    if (!isUnValidate) {
      const comImg = new CompressImage({ files: [rawFile] })
      const result = await comImg.compressImg()
      if (result) {
        // 如果压缩后图片依然超过规定大小
        isUnValidate = (result?.file?.size || 0) / 1024 / 1024 > props.fileSize
        resultFile = result?.file
      }
    }
    return new Promise((resolve, reject) => {
      if (!isJPG) {
        ElMessage.error('图片必须是 jpg、jpeg、png 格式!')
        return reject()
      } else if (isUnValidate) {
        ElMessage.error(`上传图片的大小不能超过 10 M`)
        return reject()
      } else if (isMax) {
        ElMessage.error(`图片不能超过 ${maxLimit} 张!`)
        return reject()
      }
      return resolve(resultFile)
    })
  }

  const updateRadioContent = (id: boolean) => {
    const fieldName = currentCol.value?.fieldName
    if (fieldName) {
      tableData.value[fieldName] = id
    }
  }
</script>

<style scoped lang="scss">
  @import url(./index.scss);
</style>
